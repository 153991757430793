<template>
  <div class="main_container">
    <div class="main_page">
      <div class="page_title">{{ $route.meta.title }}</div>
      <div class="page_containt">
        <div class="page_search">
          <el-row type="flex">
            <el-col class="search_col">
              <el-button type="primary" size="small" @click="doForm(null)"
                >新增广告</el-button
              >
            </el-col>
          </el-row>
        </div>
        <div class="page_box" ref="pageBox">
          <el-table
            v-loading="loading"
            default-expand-all
            row-key="id"
            size="small"
            class="el_table"
            cell-class-name="el_table_cell"
            header-row-class-name="el_table_header"
            :data="listData"
            :height="tableHeight"
            style="width: 100%"
          >
            <el-table-column label="图片" width="220">
              <template slot-scope="scope">
                <el-image
                  style="width: 200px; height: 100px"
                  :src="scope.row.web_img_path"
                  fit="contain"
                ></el-image>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="名称"> </el-table-column>
            <el-table-column label="排序号" width="80">
              <template slot-scope="scope">
                <el-input
                  size="small"
                  v-model="scope.row.order_num"
                  @blur="setItemOrder(scope.row)"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              align="right"
              header-align="right"
              label="操作"
              width="200"
            >
              <template slot-scope="scope">
                <el-button
                  class="btn"
                  type="text"
                  icon="el-icon-edit"
                  size="small"
                  @click="doForm(scope.row)"
                >
                  编辑
                </el-button>
                <el-button
                  @click="deleteItem(scope.row)"
                  class="btn"
                  type="text"
                  icon="el-icon-delete"
                  size="small"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <el-drawer
      :title="formTitle"
      size="60%"
      :visible.sync="drawer"
      :wrapperClosable="false"
      :close-on-press-escape="false"
      direction="rtl"
    >
      <div class="drawer_box">
        <el-form
          ref="modelForm"
          :rules="modelRules"
          :model="modelForm"
          label-width="100px"
          size="small"
        >
          <el-form-item label="名称" size="small" prop="name">
            <el-input
              v-model="modelForm.name"
              style="width: 300px"
              placeholder="名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="图片" size="small" prop="img_path">
            <el-button
              size="small"
              style="width: 100px"
              @click="reVisible = true"
              >+选择图片</el-button
            >
          </el-form-item>
          <el-form-item label="" size="small" prop="web_img_path" class="no-bottom">
            <div class="more_img_box">
              <div class="img_item one_img_item">
                <el-image
                  class="img"
                  :src="
                    modelForm.web_img_path
                      ? modelForm.web_img_path
                      : '/images/image-empty.png'
                  "
                  fit="contain"
                ></el-image>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="" size="small" class="msg">
            图片建议大小：750px*1035px
          </el-form-item>
          <!-- <el-form-item label="链接" size="small" prop="link_type">
            <el-select
              v-model="modelForm.link_type"
              size="small"
              popper-class="select"
              style="width: 120px"
              placeholder="请选择"
            >
              <el-option
                v-for="item in typeOptions"
                :key="item.status"
                :label="item.name"
                :value="item.status"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="小程序ID" size="small" prop="api_id" v-if="modelForm.link_type==3">
            <el-input
              v-model="modelForm.api_id"
              style="width: 300px"
              placeholder="小程序ID"
            ></el-input>
          </el-form-item>
          <el-form-item label="路径" size="small" prop="path" v-if="modelForm.link_type!=1">
            <el-input
              v-model="modelForm.path"
              style="width: 300px"
              placeholder="路径"
            ></el-input>
          </el-form-item> -->
          <el-form-item>
            <el-button
              native-type="submit"
              @click.native.prevent="handleSubmit"
              :loading="btnLogining"
              type="primary"
              >提交</el-button
            >
            <el-button size="small" @click="drawer = false">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
    <ResourceWin
      ref="ResourceWin"
      v-if="reVisible"
      :resType="1"
      :isMore="false"
      @closeResourceWin="reVisible = false"
      @setResources="setResources"
    />
  </div>
</template>
  <script>
import { onTableHeight } from "@/lib/table";
import settingApi from "@/api/setting";
import ResourceWin from "@/components/resource/win";
export default {
  components: {
    ResourceWin,
  },
  data() {
    return {
      tableHeight: 500,
      loading: false,
      btnLogining: false,
      reVisible: false,
      drawer: false,
      formTitle: "",
      model: null,
      modelForm: {
        name: "",
        web_img_path: "",
        img_path: "",
        link_type: 1,
        link_id: null,
        api_id:"",
        path:""
      },
      modelRules: {
        name: [
          { required: true, message: "请输入名称", trigger: "blur" },
          { min: 1, max: 50, message: "长度不超过100个字符" },
        ],
        img_path: [{ required: true, message: "请选择图片" }],
      },
      listData: [],
      typeOptions: [
        {
          status: 1,
          name: "无链接",
        },
        {
          status: 2,
          name: "当前小程序",
        },
        {
          status: 3,
          name: "其他小程序",
        },
      ],
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    onTableHeight(this, false);
  },
  methods: {
    //表单
    doForm(row) {
      this.model = row;
      this.formTitle = this.model == null ? "新增广告" : "编辑广告";
      this.modelForm={
        name: "",
        web_img_path: "",
        img_path: "",
        link_type: 1,
        link_id: null,
        api_id:"",
        path:""
      };
      this.$nextTick(() => {
        if (this.model != null) {
          this.modelForm.name = this.model.name;
          this.modelForm.web_img_path = this.model.web_img_path;
          this.modelForm.img_path = this.model.img_path;
          this.modelForm.link_type = this.model.link_type;
          this.modelForm.link_id = this.model.link_id;
          this.modelForm.api_id = this.model.api_id;
          this.modelForm.path = this.model.path;
        }
      });
      this.drawer = true;
    },
    //列表
    getList() {
      this.loading = true;
      settingApi
        .bannerList()
        .then((res) => {
          this.listData = res.list;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    //设置排序号
    setItemOrder(row) {
      let message = this.$message({
        message: "请求中",
        iconClass: "el-icon-loading",
        customClass: "message-loading",
        duration: 0,
        offset: 60,
      });
      settingApi
        .bannerOrder({
          id: row.id,
          order_num: row.order_num,
        })
        .then((res) => {
          this.$message({
            message: "设置成功",
            type: "success",
            offset: 60,
          });
          this.getList();
        })
        .catch(() => {})
        .finally(() => {
          message.close();
        });
    },
    //删除
    deleteItem(row) {
      this.$confirm("确定是否删除该广告?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let message = this.$message({
            message: "请求中",
            iconClass: "el-icon-loading",
            customClass: "message-loading",
            duration: 0,
            offset: 60,
          });
          settingApi
            .bannerDelete({
              id: row.id,
            })
            .then((res) => {
              this.$message({
                message: "删除成功",
                type: "success",
                offset: 60,
              });
              this.getList();
            })
            .catch((res) => {})
            .finally(() => {
              message.close();
            });
        })
        .catch(() => {});
    },
    handleSubmit() {
      this.$refs.modelForm.validate((valid) => {
        if (valid) {
          this.btnLogining = true;
          settingApi
            .bannerForm({
              id: this.model == null ? 0 : this.model.id,
              ...this.modelForm,
            })
            .then((res) => {
              this.$message({
                message: "提交成功",
                type: "success",
                offset: 60,
              });
              this.drawer = false;
              this.getList();
            })
            .catch((res) => {})
            .finally(() => {
              this.btnLogining = false;
            });
        } else {
          return false;
        }
      });
    },
    //设置资源回调方法
    setResources(data) {
      this.modelForm.img_path = data.files[0].path;
      this.modelForm.web_img_path = data.files[0].web_path;
      this.reVisible = false;
    },
  },
};
</script>
  <style scope>
</style>
  